:root {
  --black: #161616;
  --dark: #d00e24;
  --main: #DE1F32;
  --light: #F1F1F1;
  --grey: #d9d9d9;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main);
  overflow-x: hidden;
}

html {
  overflow-x: hidden !important;
}

::-moz-selection {
  background: var(--light);
  color: var(--main);
}

::selection {
  background: var(--light);
  color: var(--main);
}

@font-face {
  font-family: 'yt-medium';
  src: url('./fonts/youtube-sans-medium.ttf');
}

@font-face {
  font-family: 'yt-bold';
  src: url('./fonts/youtube-sans-bold.ttf');
}

@font-face {
  font-family: 'yt-light';
  src: url('./fonts/youtube-sans-light.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
p,
h1,
h2,
span {
  margin: 0;
  padding: 0;
  color: var(--light);
}

a,
p,
span {
  font-family: 'yt-light';
}

h1,
h2 {
  font-family: 'yt-bold';
}

img {
  user-select: none;
}

.lk {
  height: 0px;
  line-height: 0%;
  opacity: 0;
  margin: 0;
  font-size: 0em;
  padding: 0;
  margin-top: 50px;
}

button {
  border: none;
}

iframe {
  padding: 0 !important;
}

strong {
  font-family: 'yt-medium';
  font-weight: 400;
}

p {
  line-height: 128%;
}