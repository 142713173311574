.Home {
    height: auto;
    min-height: 100vh;
    padding-top: 17vh;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: center;
}

.master {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.left button {
    font-family: 'yt-medium';
    margin-top: 50px;
    background-color: var(--light);
    border-radius: 12px;
    color: var(--main);
    font-size: 28px;
    text-align: center;
    box-shadow: 0 0 0em var(--main);
    transition: all.2s;
}

.left button:hover {
    cursor: pointer;
    transform: translateY(-.2em);
    box-shadow: 0 .2em .7em var(--black);
}

.left p {
    font-family: 'yt-medium';
}

.right {
    position: relative;
}

.right img {
    position: absolute;
    filter: none !important;
    width: auto !important;
    margin: 0 !important;
}

.right img:first-child {
    top: 0;
    left: 0;
    z-index: 1;
}

.right img:last-child {
    top: 25px;
    right: 0;
}

h1,
h2,
p {
    text-align: left;
}

p {
    margin-top: 15px;
    margin-bottom: 15px;
}

.Home img {
    width: 100px;
    height: auto;
    margin: 2vh;
    filter: grayscale(1) invert(1) brightness(17);
}

.spot,
.episodes {
    margin-left: auto;
    margin-right: auto;
}

.spot {
    background-color: var(--grey);
}

.spot p,
.episodes p,
.flex p {
    width: 90%;
}

.spot p {
    color: var(--black);
}

.episodes p {
    color: var(--light);
}

.spot h1 {
    color: var(--main);
}

.spot-elmt {
    margin-top: 50px;
    border-radius: 12px;
    box-shadow: 0 0 4em rgb(0, 0, 0, .4);
    height: 64vh;
    width: 90%;
    border: none;
}

.spot-elmt-small {
    margin-top: 50px;
    border: none;
}

.spot h2,
.episodes h2 {
    text-align: left;
    letter-spacing: normal;
}

@media only screen and (max-width: 1000px) {

    p {
        font-size: 28px;
    }

    .boxes div {
        width: 450px;
        height: 400px;
        max-width: 95vw;
    }

    .left button {
        font-size: 1.7em;
        width: 95%;
        padding: 4%;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.2em;
    }

    h1 {
        text-align: center;
        font-size: 72px;
    }

    h2 {
        text-align: center;
        font-size: 30px;
        letter-spacing: 13px;
        transform: translateX(4px);
    }

    .right {
        padding-top: 70vh !important;
        background-size: 100%;
        background-position: 20% 20%;
        filter: drop-shadow(3em 3em 1em var(--black));
    }

    .left,
    .right {
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .right img {
        height: 60vh !important;
        float: center;
    }

    .spot,
    .episodes {
        width: 100%;
        padding: 5vh 0;
    }

    .spot-elmt-small {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        height: 81px;
        padding: 0 !important;
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
    }

    .spot h1,
    .episodes h1 {
        font-size: 2.5em !important;
    }

    .flex_logos img {
        height: 8vh !important;
    }

    .flex_logos .small {
        height: 5vh !important;
        margin-top: 2vh !important;
    }

}

@media only screen and (min-width: 1000px) {

    p {
        font-size: 1.4em;
    }

    .spot,
    .episodes {
        width: 90%;
        padding: 5%;
    }

    .boxes div {
        width: 300px;
        height: 300px;
    }

    .master {
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
    }

    .left {
        width: 25vw;
        max-width: 375px;
        min-width: 430px;
    }

    .right {
        max-width: 825px;
        width: 55vw;
    }

    .left button {
        width: 410px;
        padding: 20px;
    }

    p {
        width: 450px;
    }

    h1 {
        font-size: 124px;
    }

    h2 {
        font-size: 49px;
        text-align: center;
        letter-spacing: 20px;
        transform: translateX(11px);
    }

    .right {
        background-size: 84%;
        background-position: 150% 15%;
        filter: drop-shadow(3em 3em 1em var(--black));
    }

    .left,
    .right {
        margin: 25px auto;
        min-height: 900px;
    }

    .right img {
        height: 50vh !important;
        min-height: 825px !important;
        float: left;
    }

    .spot-elmt-small {
        width: 300px;
        height: 352px;
        border-radius: 12px;
        padding: 0;
        box-shadow: 0 0 4em rgb(0, 0, 0, .4);
    }

    .flex {
        display: flex;
        gap: 10vw;
    }

    .spot p,
    .episodes p {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .flex_logos img {
        height: 10vh !important;
    }

    .flex_logos .small {
        height: 8vh !important;
        margin-top: 2vh !important;
    }

}

.boxes {
    width: 100%;
    max-width: 1400px;
    margin-top: 100px !important;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.boxes div {
    position: relative;
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    transition: all.2s;
}

.boxes div img {
    filter: none;
    box-shadow: 0 0 4em rgb(0, 0, 0, .4);
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    border-radius: 12px;
    transition: all.2s;
}

.boxes div span {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: calc(100% - 18px);
    height: calc(100% - 18px);
    text-align: center;
    background-color: rgb(208, 14, 36, .7);
    font-size: xx-large;
    padding: 9px;
    border-radius: 12px;
    transition: all.2s;
}

.boxes div:hover {
    cursor: pointer;
}

.boxes div:hover img,
.boxes div:hover span {
    transform: scale(-1, 1) rotateY(180deg);
}

.boxes div:hover span {
    opacity: 1;
    backdrop-filter: blur(.2em);
}

.boxes div h2 {
    position: absolute;
    top: -1.2em;
    right: 0;
    width: fit-content;
}

.logos {
    position: relative;
    display: inline-flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    text-align: center;
    padding: 0;
}

.logos a {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

.logos img {
    filter: none !important;
    max-width: 20vw !important;
}

.center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.flex_logos {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    padding: 0;
}

.flex_logos a {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

.flex_logos img {
    filter: none !important;
    width: auto !important;
    max-height: none !important;
    max-width: none !important;
}

.black {
    color: var(--black) !important;
}

.red {
    color: var(--main) !important;
}